import Colors from 'const/colors';
import { DossierStatus } from 'types/Dossier';

const jaune = Colors.GrandPublic;
const marron = Colors.GrandPublic;
const orange = Colors.GrandPublic;
const rouge = Colors.Collectivites;
const vert = Colors.Agriculture;
const gris = Colors.Industriels;

export const getDossierStatusColor = (status: DossierStatus): string => {
  // Référence couleurs: https://docs.google.com/spreadsheets/d/11_BRm3rdT6hP_KuUXPs7yLJDyG4Xz65vg1oH1sSk6ig/edit?gid=0#gid=0
  switch (status) {
    case DossierStatus.EtudeEnCours:
    case DossierStatus.OffreEmise:
    case DossierStatus.ChantierEnCours:
    case DossierStatus.DocumentsEnAttente:
      return jaune;

    case DossierStatus.Recu:
      return marron;

    case DossierStatus.EnAttenteDeControle:
      return orange;

    case DossierStatus.Incomplet:
      return rouge;

    case DossierStatus.Valide:
    case DossierStatus.PrimeProPayee:
    case DossierStatus.PrimeBeneficiairePayee:
    case DossierStatus.PrimeGlobalePayee:
      return vert;

    case DossierStatus.Refuse:
    case DossierStatus.Abandon:
      return gris;
    case DossierStatus.DemandeInformations:
      return jaune;

    case DossierStatus.EnCoursDeValidation:
      return orange;

    default:
      return gris;
  }
};
