import Colors from 'const/colors';
import { OperationStatus } from 'types/Operation';

const orange = Colors.GrandPublic;
const red = Colors.Collectivites;
const green = Colors.Agriculture;
const gray = Colors.Industriels;

export const getDossierOperationStatusColor = (
  status: OperationStatus,
): string => {
  switch (status) {
    case OperationStatus.Litige:
    case OperationStatus.Incomplet:
      return red;
    case OperationStatus.Refuse:
      return gray;
    case OperationStatus.BonPourPrime:
      return green;
    case OperationStatus.RemisAuControle:
    case OperationStatus.DossierEnCoursDeValidation:
    case OperationStatus.PreValide:
    case OperationStatus.ActionEnCours:
    case OperationStatus.NonConforme:
    default:
      return orange;
  }
};
