import 'theme/overrides.scss';

import { ConfigProviderProps } from 'antd';
import frFR from 'antd/locale/fr_FR';
import theme from 'theme/theme.json'; // à modifier dans https://ant.design/theme-editor

const appTheme: ConfigProviderProps = {
  locale: frFR,
  theme,
  card: {
    className: ' border-none shadow-sm shadow-slate-10 px-4 py-6',
  },
};

export default appTheme;
