import React from 'react';
import clsx from 'clsx';

export default function StatusTag({
  status,
  color,
  full,
  className,
}: {
  status?: string;
  color: string;
  full?: boolean;
  className?: string;
}) {
  return status !== undefined ? (
    <div
      className={clsx(
        'inline-block py-2 px-5 rounded-md -my-2 text-center text-white',
        {
          'w-full': full,
        },
        className,
      )}
      style={{ backgroundColor: color }}
    >
      {status}
    </div>
  ) : null;
}
