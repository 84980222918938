export function setToLocalStorage<T>(key: string, data: T): void {
  try {
    const serializedData = JSON.stringify(data);
    localStorage.setItem(key, serializedData);
  } catch (error) {
    console.error('Erreur lors de la sauvegarde dans setocalStorage:', error);
  }
}

export function getFromLocalStorage<T>(key: string): T | null {
  try {
    const serializedData = localStorage.getItem(key);
    return serializedData ? (JSON.parse(serializedData) as T) : null;
  } catch (error) {
    console.error(
      'Erreur lors de la récupération des données depuis' +
        ' getFromLocalStorage:',
      error,
    );
    return null;
  }
}

export function removeToLocalStorage(key: string): void {
  localStorage.removeItem(key);
}
