import React, {
  Context,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import SecureLS from 'secure-ls';
import { useUserSFOwner } from 'views/auth/hook/auth_hook';

import AuthService from 'services/api/AuthService';

import { GetSFUserResponse, User } from 'types/User';

const ls = new SecureLS({ encodingType: 'aes' });

interface AuthPayload {
  token: string;
  user: User;
}

export interface AuthContextType {
  auth?: AuthPayload;
  owner?: GetSFUserResponse[];
  ownerIsLoading?: boolean;
  login: (auth: AuthPayload) => void;
  logout: () => void;
}

export const AuthContext: Context<AuthContextType> =
  React.createContext<AuthContextType>({
    auth: undefined,
    owner: undefined,
    ownerIsLoading: false,
    login: () => {},
    logout: () => {},
  });

interface AuthContextProviderProps {
  children: React.ReactNode;
}

const getUser = () => {
  try {
    return ls.get('user') ? ls.get('user') : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
};

export const getToken: () => string = () => {
  try {
    return ls.get('token') ? ls.get('token') : undefined;
  } catch (error) {
    console.warn(error);
    return undefined;
  }
};

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  const { data: owner, isLoading: ownerIsLoading } = useUserSFOwner();

  const user = useMemo(getUser, []);

  const token = useMemo(getToken, []);

  const initialAuth = user && token ? { user, token } : undefined;

  const [auth, setAuth] = useState<AuthContextType['auth']>(initialAuth);

  const login: AuthContextType['login'] = (auth) => {
    setAuth(auth);
    ls.set('token', auth?.token);
    ls.set('user', auth?.user);
  };

  const logout = () => {
    setAuth(undefined);
    ls.remove('token');
    ls.remove('user');
  };

  const refreshCurrentUser = useCallback(() => {
    if (token) {
      AuthService.get_userInfo()
        .then(async (res: Response) => {
          if (res.status === 200 || res.status === 201) {
            const user = await res.json();
            login({
              user,
              token,
            });
          }
        })
        .catch((error: Error) => {
          console.warn(
            'Erreur lors de la récupération des informations utilisateur :',
            error.message,
          );
        });
    }
  }, [token]);

  useEffect(() => {
    refreshCurrentUser();
  }, [refreshCurrentUser]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        owner,
        ownerIsLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext: () => AuthContextType = () =>
  useContext(AuthContext);
