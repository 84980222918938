import { Attachment } from 'types/Attachment';

export enum OperationStatus {
  Eligible = 'Éligible',
  AConfirmer = 'Éligibilité à confirmer',
  NonConfirme = 'Eligibilité non confirmée à ce stade',
  Recommandations = 'Recommandations',
  Annule = 'Annulé',
  DossierEnCoursDeValidation = 'Dossier en cours de validation',
  Incomplet = 'Incomplet',
  RemisAuControle = 'Remis au Contrôle',
  PreValide = 'Pré validé',
  BonPourPrime = 'Bon pour Prime',
  NonConforme = 'Non conforme (Qualité)',
  ActionEnCours = 'Action en cours',
  Litige = 'Litige',
  Refuse = 'Refusé',
  Retire = 'Retiré du dépôt',
}

export const OperationStatusKeys = Object.fromEntries(
  Object.entries(OperationStatus).map(([key, value]) => [value, key]),
);

export interface OperationHistoryItem {
  date: string;
  value: OperationStatus;
}

export interface Operation {
  id: string;
  libelle?: string;
  fost?: string;
  montantPrime?: number;
  statut?: OperationStatus;
}

export interface OperationFull extends Operation {
  criteresEligibilite?: string;
  mentionsFacture?: string;
}

export interface OperationDetails {
  operation: OperationFull;
  history: OperationHistoryItem[];
  attachments: Attachment[];
}

export interface Operations {
  records: OperationDetails[];
  totalSize: number;
}

export enum SecteurOperations {
  INDUSTRIE = 'Industrie',
  RESEAUX = 'Réseaux',
  TERTIAIRE = 'Tertiaire',
  AGRICULTURE = 'Agriculture',
  RESIDENTIEL = 'Résidentiel',
}
