import React from 'react';
import { StatusTag } from 'components';
import { getDossierStatusColor } from 'const/statutsDossier';
import { DossierStatus, DossierStatusKeys } from 'types/Dossier';
import { t } from 'i18next';

export default function DossierStatusTag({
  full,
  status,
}: {
  status?: DossierStatus;
  full?: boolean;
}) {
  if (status === undefined) {
    return null;
  }
  const statusValue = t(`statuts_dossier:${DossierStatusKeys[status]}`);
  return (
    <StatusTag
      full={full}
      status={statusValue}
      color={getDossierStatusColor(status)}
      className="!py-1 -my-1 !px-3 align-middle"
    />
  );
}
