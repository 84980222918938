import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Divider, Popover, Spin, Typography } from 'antd';

import { useAuthContext } from 'contexts/AuthContext';

import { ReactComponent as PhoneIcon } from 'assets/icons/Phone.svg';

export const ContactPopover: React.FC = () => {
  const { t } = useTranslation('contact');
  const [open, setOpen] = useState(false);
  const { owner: data, ownerIsLoading: isLoading } = useAuthContext();

  return (
    <Popover
      trigger="click"
      onOpenChange={(visible: boolean): void => {
        setOpen(visible);
      }}
      open={open}
      rootClassName={'no-padding-popover white-popover'}
      color={'#FFF'}
      content={
        <>
          <div className={'flex justify-between items-center'}>
            <Typography.Paragraph className={'!mb-0 font-bold'}>
              {t('title')}
            </Typography.Paragraph>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={(): void => {
                setOpen(false);
              }}
            />
          </div>
          <Divider className={'m-0'} />
          <div className={'py-4'}>
            {isLoading && <Spin className={'mx-auto'} />}
            {!isLoading && !data?.length && (
              <Typography.Paragraph className={'!mb-0'}>
                {t('no_contact')}
              </Typography.Paragraph>
            )}
            {data?.length && (
              <ul className="!mb-0 leading-8">
                <li className="block !m-0" key="owner-name">
                  {data[0]?.Owner?.Name}
                </li>
                {data[0]?.Owner?.MobilePhone && (
                  <li key="owner-phone" className="block !m-0">
                    {t('global:phone')}:{' '}
                    <a href={`tel:${data[0]?.Owner?.MobilePhone}`}>
                      {data[0]?.Owner?.MobilePhone}
                    </a>
                  </li>
                )}
                {data[0]?.Owner?.Email && (
                  <li key="owner-email" className="block !m-0">
                    {t('global:email')}:{' '}
                    <a href={`mailto:${data[0]?.Owner?.Email}`}>
                      {data[0]?.Owner?.Email}
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
        </>
      }
      placement={'bottom'}
    >
      <PhoneIcon />
    </Popover>
  );
};
