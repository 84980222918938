import React from 'react';
import { Flex, Typography, Grid } from 'antd';
import { ReactComponent as BigLogo } from 'assets/logo/logo-big.svg';
const { Title } = Typography;
const { useBreakpoint } = Grid;

interface PageHeaderProps {
  title: string;
  children?: React.ReactNode;
  right?: React.ReactNode;
  bottom?: React.ReactNode;
  align?: React.CSSProperties['alignItems'];
}

const PageHeader = (props: PageHeaderProps) => {
  const { title, children, right, bottom, align } = props;
  const { xl, lg } = useBreakpoint();

  return (
    <>
      <Flex gap="large" align={align || 'start'}>
        {xl && (
          <BigLogo className="print:hidden" style={{ minWidth: '285px' }} />
        )}

        <Flex vertical gap="large" className={'grow'}>
          <Flex gap="large" align="flex-start">
            <Flex vertical gap="large" className={'grow'}>
              <Title level={1} className="!m-0">
                {title}
              </Title>
              {children}
            </Flex>

            {lg && right}
          </Flex>
          {bottom}
        </Flex>
      </Flex>
      {!lg && right}
    </>
  );
};

export default PageHeader;
