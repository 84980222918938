import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { format, formatDistance } from 'date-fns';
import * as dateLocales from 'date-fns/locale';

export function useDateLocale() {
  const { t } = useTranslation();
  const [locale] = t('translation:locale').split('-');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dateLocales as any)?.[locale] || dateLocales.enGB;
}

export function useDateFormat() {
  const locale = useDateLocale();
  return useCallback(
    (date: Date | string, pattern: string): string =>
      format(date, pattern, { locale }),
    [locale],
  );
}

export function useDateFormatDistance() {
  const locale = useDateLocale();
  return useCallback(
    (date: Date | string, pattern: string): string =>
      formatDistance(date, pattern, { locale }),
    [locale],
  );
}

export default useDateFormat;
