import React, { ReactNode } from 'react';

interface Props {
  label: ReactNode;
  value: ReactNode;
}

export default function SelectSimpleOption({ label, value }: Props) {
  return (
    <div className="flex flex-col">
      <span className="text-sm font-medium">{label}</span>
      <span className="text-xs">{value}</span>
    </div>
  );
}
