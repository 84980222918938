import React from 'react';
import { MenuProps } from 'antd';
import { ContactPopover } from 'layouts/Component/ContactPopover';

import { NavigationItemInterface } from 'interfaces/navigationItem.interface';

import { ReactComponent as BellIcon } from 'assets/icons/Bell.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/PersonFill.svg';

export type MenuItem = Required<MenuProps>['items'][number];

export const topNavigationItems: NavigationItemInterface[] = [
  {
    key: '/',
    label: 'Actualités',
  },
  {
    key: '/calculer-une-prime',
    label: 'Calculer une prime',
  },
  {
    key: '/soumission-de-dossier',
    label: 'Soumission de dossier',
  },
  {
    key: '/dossiers',
    label: 'Suivi de dossiers',
  },
  {
    key: '/faq',
    label: 'FAQ',
  },
  {
    key: 'contact',
    icon: <ContactPopover />,
  },
  {
    key: '/mon-compte',
    icon: <PersonIcon />,
  },
  {
    key: 'notifications',
    icon: <BellIcon />,
  },
];

export const bottomNavigationItems: NavigationItemInterface[] = [
  {
    key: '/',
    label: 'Actualités',
  },
  {
    key: '/calculer-une-prime',
    label: 'Calculer une prime',
  },
  {
    key: '/soumission-de-dossier',
    label: 'Soumission de dossier',
  },
  {
    key: '/dossiers',
    label: 'Suivi de dossiers',
  },
  {
    key:
      'https://www.ecologie.gouv.fr/politiques-publiques/' +
      'dispositif-certificats-deconomies-denergie',
    label: 'Réglementation',
    target: '_blank',
  },
  {
    key: '/faq',
    label: 'FAQ',
  },
  {
    key: 'https://www.hellio.com/mentions-legales',
    label: 'Mentions légales',
    target: '_blank',
  },
  {
    key: 'https://www.hellio.com/cgu',
    label: "Conditions générales d'utilisation",
  },
  {
    key: 'https://www.hellio.com/politique-confidentialite',
    label: 'Politique de confidentialité',
  },
];

export default topNavigationItems;
