import React from 'react';
import { StatusTag } from 'components';
import { getDossierOperationStatusColor } from 'const/statutsDossierOperation';
import { OperationStatus, OperationStatusKeys } from 'types/Operation';
import { useTranslation } from 'react-i18next';

export default function OperationStatusTag({
  full,
  status,
}: {
  status?: OperationStatus;
  full?: boolean;
}) {
  const { t } = useTranslation();
  if (status === undefined) {
    return null;
  }
  return (
    <StatusTag
      full={full}
      status={t(`statuts_operation:${OperationStatusKeys[status]}`)}
      color={getDossierOperationStatusColor(status)}
    />
  );
}
