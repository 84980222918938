export default {
  translation: {
    locale: 'fr-FR',
    'shared.french': 'Français',
    'app.name': 'Espace Partenaire Hellio',
    'auth.hero': 'Bienvenue sur votre\nEspace Partenaire',
  },
  global: {
    password: 'Mot de passe',
    email: 'E-mail',
    phone: 'Téléphone',
    logout: 'Déconnexion',
    cancel: 'Annuler',
    required: 'Ce champ est obligatoire',
    internet_connexion_error:
      "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
    'email.required': 'Veuillez renseigner une adresse e-mail valide',
    session_expired: 'Votre session a expiré. Veuillez vous reconnecter.',
    previews: 'Précédents',
    back: 'Précédent',
    next: 'Suivant',
    yes: 'Oui',
    no: 'Non',
    date: 'Date',
    mr: 'Monsieur',
    mrs: 'Madame',
    chargement: 'Chargement...',
    invalid_email: 'Veuillez renseigner une adresse e-mail valide',
    invalid_phone_number: 'Veuillez renseigner un numéro de téléphone valide',
  },
  filesize: {
    symbols: {
      iec: {
        bytes: {
          B: 'o',
          KiB: 'Kio',
          MiB: 'Mio',
          GiB: 'Gio',
          TiB: 'Tio',
          PiB: 'Pio',
          EiB: 'Eio',
          ZiB: 'Zio',
          YiB: 'Yio',
        },
      },
      jedec: {
        bytes: {
          B: 'o',
          KB: 'Ko',
          MB: 'Mo',
          GB: 'Go',
          TB: 'To',
          PB: 'Po',
          EB: 'Eo',
          ZB: 'Zo',
          YB: 'Yo',
        },
      },
    },
  },
  statuts_dossier: {
    EtudeEnCours: 'Étude en cours',
    OffreEmise: 'Offre émise',
    ChantierEnCours: 'Chantier en cours',
    DocumentsEnAttente: 'Documents en attente',
    Recu: 'Reçu',
    EnAttenteDeControle: 'En attente bureau de contrôle',
    Incomplet: 'Incomplet',
    Valide: 'Validé',
    PrimeProPayee: 'Prime Pro payée',
    PrimeBeneficiairePayee: 'Prime Bénéficiaire payée',
    PrimeGlobalePayee: 'Prime Globale Payée',
    Refuse: 'Refusé',
    Abandon: 'Abandon',
    DemandeInformations: "Demande d'informations",
    EnCoursDeValidation: 'En cours de validation',
  },
  statuts_operation: {
    Eligible: 'Éligible',
    AConfirmer: 'Éligibilité à confirmer',
    NonConfirme: 'Eligibilité non confirmée à ce stade',
    Recommandations: 'Recommandations',
    Annule: 'Annulé',
    DossierEnCoursDeValidation: 'Dossier en cours de validation',
    Incomplet: 'Incomplet',
    RemisAuControle: 'Remis au Contrôle',
    PreValide: 'Pré validé',
    BonPourPrime: 'Bon pour Prime',
    NonConforme: 'Non conforme (Qualité)',
    ActionEnCours: 'Action en cours',
    Litige: 'Litige',
    Refuse: 'Refusé',
    Retire: 'Retiré du dépôt',
  },
  login: {
    hero: 'Connexion',
    description: 'Renseignez votre adresse email et votre mot de passe',
    forgot: 'Mot de passe oublié',
    login_error: 'Votre identifiant ou votre mot de passe est incorrect',
    submit: 'Se connecter',
  },
  first_login: {
    hero: 'Première connexion',
    description: 'Renseignez votre mot de passe',
    success: 'Création de votre mot de passe',
    newPassword: 'Nouveau mot de passe*',
    error: "Votre lien à expiré ou n'est plus valide",
    success_description:
      'La création de votre mot de passe a bien été prise en compte.',
    success_action: 'Me connecter',
    submit: 'Valider le mot de passe',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  change_password: {
    hero: 'Changement de mot de passe',
    newPassword: 'Nouveau mot de passe*',
    description: 'Renseignez votre nouveau mot de passe',
    submit: 'Valider le mot de passe',
    success: 'Modification du mot de passe',
    success_description:
      'La modification de votre mot de passe a bien été effectuée.',
    success_action: 'Me connecter',
    same: 'Les mots de passes ne sont pas identiques',
    'confirm.password': 'Confirmer le nouveau mot de passe*',
    'confirm.regex.error':
      'Le mot de passe doit contenir 8 caractères : une majuscule, un chiffre et un caractère spécial.',
    'confirm.placeholder.password': 'Confirmation du mot de passe',
  },
  404: {
    hero: 'Page introuvable',
    description: "La page que vous cherchez n'existe pas ou a été déplacée.",
    back: "Retour à l'accueil",
  },
  not_implemented: {
    hero: 'Non implémenté',
  },
  reset_password: {
    hero: 'Mot de passe oublié',
    description:
      'Veuillez saisir votre email de connexion afin de recevoir le lien de réinitialisation de votre mot de passe.',
    submit: 'Valider',
    error: "Votre lien à expiré ou n'est plus valide",
    back: 'Retour à la connexion',
    success: 'Réinitialisation du mot de passe',
    success_title: 'Réinitialisation du mot de passe',
    success_description:
      "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe. Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur le lien contenu dans l'e-mail et d'en saisir un nouveau.<br>" +
      "<strong>Vous n'avez pas reçu cet e-mail</strong><br> Vérifiez vos courriers indésirables ou contactez : ",
    email: 'plateforme-partenaires@hellio.com',
  },
  homepage: {
    hero: 'Accueil',
  },
  contact: {
    title: 'Votre référent Hellio',
    no_contact: 'Aucun référent commercial n’est rattaché à votre compte.',
  },
  calculer_une_prime: {
    hero: 'Calculer une prime',
    description:
      'Calculez la prime CEE Hellio associée à votre opération en quelques clics.',
    title: 'Calculateur de prime',
    champs_requis: {
      champs: "Les champs accompagnés d'un ",
      requis: ' sont requis.',
    },
    operation: 'Choisissez une opération parmi la liste',
    operation_date: "Date prévisionnelle de l'opération ",
    code_postal_label: 'Saisissez une ville ou un code postal',
    entrez_code_postal: 'Entrez un code postal',
    batiment: {
      question: "De quel type de bâtiment s'agit-il ? ",
      agriccole: 'Bâtiment agricole',
      industriel: 'Bâtiment industriel',
      tertiaire: 'Bâtiment tertiaire',
      residentiel: 'Bâtiment résidentiel',
    },
    criteres_eligibilite: "Critères d'éligibilité",
    calculer_ma_prime: 'Calculer ma prime ',
    resultat: {
      votre_situation: 'Votre situation',
      reinitialiser_ma_simulation: 'Réinitialiser ma simulation',
      type_de_batiment: 'Type de bâtiment',
      mode_de_fonctionnement_du_site: 'Mode de fonctionnement du site',
      type_operation: "Type d'opération (FOST)",
      puissance_nominale_du_moteur: 'Puissance nominale du moteur (en Kw',
      code_postal: 'Code postal',
      montant_de_la_prime_CEE: 'Montant de la prime CEE',
      prime_MPR: 'Prime MPR',
      montant_de_la_prime_MPR: 'Montant de la prime MPR',
      montant_de_la_prime_description:
        'Le montant de prime estimé indiqué ici est calculé sur la base des paramètres que vous avez indiqué dans le calcul. Ce montant est conditionné au respect des critères d’éligibilité CEE et est susceptible d’évoluer en fonction des travaux effectivement réalisés et de l’évolution des paramètres déclarés à ce stade',
      volume_de_CEE_reel: 'Volume de CEE réel',
      prime_globale: 'Prime globale',
      prime_pro: 'Prime professionnel',
      prime_beneficiaire: 'Prime bénéficiaire',
      prime_professionnel: 'Prime professionnel',
      repartition_beneficiaire_pro:
        'Répartition CEE bénéficiaire /' + ' professionnel',
      montant_max_prime:
        'Le montant maximum de la Prime Professionnel ne peut dépasser 50%',
      montant_prime_rappel_warning:
        'Nous vous rappelons de faire valider votre devis avec remise par' +
        ' nos équipes spécialisées avant de le faire signer à vos clients.',
      enregistrer_ma_simulation: 'Enregistrer ma simulation',
      precedent: 'Précédent',
      soumettre_un_dossier: 'Soumettre un dossier',
      enregistrer_une_simulation: 'Enregistrer une simulation',
      nom_de_la_simulation: 'Nom de la simulation',
      transformer_simulation_dossier:
        'Voulez-vous transformer votre simulation en dossier ?',
      completer_informations_chantier:
        ' Vous serez invité à compléter les informations du chantier.',
      oui_completer_informations: 'Oui, je complète les informations',
      non_je_nomme_simulation: 'Non, je nomme ma simulation',
      veuillez_nommer_simulation: 'Veuillez nommer votre simulation',
      nom_obligatoire: 'Le nom de la simulation est obligatoire',
      nom_placeholder: 'Nom de la simulation',
      valider: 'Valider',
      confirmation_enregistrement: {
        sauvegarde_de_simulation: 'Sauvegarde de la simulation',
        simulation_bien_enregistree: 'Votre simulation a bien été enregistrée',
        simulation_probleme_enregistrement:
          'Un problème est survenu lors de l’enregistrement de votre simulation',
        acceder_historique_simulation: "Accéder à l'historique des simulations",
      },
      error: {
        montant_prime_non_calculable:
          "Le montant de la prime n'est pas calculable en l'état.",
        information_referent:
          'Votre référent Hellio a été informé et va' +
          ' apporter les corrections nécessaires.',
      },
      revenir_a_la_calculette: 'Revenir à la calculette',
    },

    travaux_mpr: {
      type_travaux_mpr: {
        title: 'Type de travaux MPR',
        placeholder: 'Type de travaux MPR',
        label: 'Saisissez un type de travaux.',
        required: 'Veuillez sélectionner un type de travaux MPR.',
      },
      nombre_personnes_logement_mpr: {
        title: 'Nombre de personnes dans le logement',
        required: 'Veuillez saisir le nombre de personnes dans le logement.',
      },
      revenu_fiscal_reference: {
        title: 'Revenu fiscal de référence',
        required: 'Veuillez sélectionner le revenu fiscal de référence.',
      },
      montant_travaux_ttc: {
        title: 'Montant des travaux TTC',
        required: 'Veuillez saisir le montant des travaux TTC.',
      },
    },
  },
  soumettre_un_dossier: {
    hero: "Soumission d'un dossier",
    etapes: {
      etape_1: 'Informations chantier',
      etape_2: 'Informations site',
      etape_3: 'Informations bénéficiaire',
      etape_4: 'Informations complémentaires',
    },
    champs_requis: {
      champs: "Les champs accompagnés d'un ",
      requis: ' sont requis.',
    },
    champs: {
      startDate: {
        selectionnez_date: 'Sélectionnez une date',
        value: 'Date de début des travaux',
        invalid:
          'La date de début des travaux doit être inférieure à la date de fin des travaux',
      },
      endDate: {
        value: 'Date prévisionnelle de fin des travaux',
        invalid:
          'La date de fin des travaux doit être supérieure à la date de début des travaux',
      },
      buildingType: {
        label: 'Type de logement',
        placeholder: 'Sélectionnez un type de logement',
        options: {
          maison: 'Maison individuelle',
          appartement: 'Appartement',
          monopropriete: 'Monopropriété',
          copropriete_immatriculee: 'Copropriété immatriculée',
          copropriete_non_immatriculee: 'Copropriété non immatriculée',
        },
      },
      siteAddress: {
        address: {
          label: 'Adresse du chantier',
          placeholder: 'Adresse du chantier',
          defaultOption: {
            value: 'Je ne trouve pas l’adresse du chantier',
          },
        },
        name: {
          label: 'Nom du site du chantier',
          placeholder: 'Nom du site du chantier',
        },
        code_postal: {
          label: 'Code postal du chantier',
          placeholder: 'Code postal du chantier',
        },
        ville: {
          label: 'Ville du chantier',
          placeholder: 'Ville du chantier',
        },
        street: {
          label: 'Adresse du chantier',
          placeholder: 'Adresse du chantier',
        },
        nbBuildings: {
          label: 'Nombre de bâtiments',
          placeholder: 'Nombre de bâtiments',
        },
        nbUnits: {
          label: "Nombre de lots d'habitation",
          placeholder: "Nombre de lots d'habitation",
        },
        heatingType: {
          label: 'Type de chauffage',
          placeholder: 'Sélectionnez un type de chauffage',
          options: {
            combustible: 'Combustible',
            fioul: 'Fioul',
            electric: 'Electricité',
            gas: 'Gaz',
          },
        },
        registrationNumber: {
          label: 'N° d’immatriculation au registre des copropriétés',
          placeholder: 'N° d’immatriculation au registre des copropriétés',
        },
        distribution: {
          label: 'Mode de répartition du chauffage',
          options: {
            collective: 'Collectif',
            individual: 'Individuel',
          },
        },
        constructionYear: {
          label: 'Année de construction',
          placeholder: 'Sélectionnez une année de construction',
          options: {
            before1948: 'Avant 1948',
            before1960: 'Avant 1960',
            bw1960_1980: 'Entre 1960 et 1980',
            bw1980_2005: 'Entre 1980 et 2005',
            after2005: 'Après 2005',
          },
        },
        postalCode: {
          label: 'Code postal du chantier',
          placeholder: 'Code postal du chantier',
        },
        city: {
          label: 'Ville du chantier',
          placeholder: 'Ville du chantier',
        },
      },
      beneficiary: {
        label: 'Type de bénéficiaire',
        options: {
          company: 'Personne morale',
          individual: 'Personne physique',
        },
        intitule: 'Informations du bénéficiaire',
      },
      siret_beneficiaire: {
        label: 'SIRET ou dénomination sociale du bénéficiaire',
        placeholder: 'SIRET ou dénomination sociale du bénéficiaire',
        defaultOption: {
          value: 'Je ne trouve pas le numéro siret du bénéficiaire',
        },
      },
      manuelSiret: {
        label: 'Saisissez le SIRET du bénéficiaire',
        placeholder: 'SIRET',
      },
      siret_entreprise_travaux: {
        label: 'SIRET ou dénomination sociale de l’entreprise de travaux',
        placeholder: 'SIRET ou dénomination sociale de l’entreprise de travaux',
        defaultOption: {
          value: "Je ne trouve pas le SIRET de l'entreprise de travaux",
        },
      },
      contactApiData: {
        label: 'Prénom et nom du contact bénéficiaire',
        placeholder: 'Prénom et nom du contact bénéficiaire',
        defaultOption: {
          value: 'Je ne trouve pas le prénom et nom du contact bénéficiaire',
        },
      },
      precariousness: {
        label: 'Statut de la précarité',
        options: {
          non_precarious: 'Non précaire',
          precarious: 'Précaire',
          very_precarious: 'Grand précaire',
        },
      },
      apiData: {
        label: 'Prénom et nom du contact bénéficiaire',
        placeholder: 'Saisissez le prénom et le nom du contact bénéficiaire',
        defaultOption: {
          value: 'Je ne trouve pas le prénom et nom du contact bénéficiaire',
        },
      },
      beneficiaire: {
        title: {
          label: 'Civilité',
        },
        lastName: {
          label: 'Nom du contact bénéficiaire',
          placeholder: 'Nom du bénéficiaire',
        },
        firstName: {
          label: 'Prénom du contact bénéficiaire',
          placeholder: 'Prénom du bénéficiaire',
        },
        email: {
          label: 'Adresse e-mail du contact bénéficiaire',
          placeholder: 'Email du bénéficiaire',
        },
        phone: {
          label: 'Téléphone du contact bénéficiaire',
          placeholder: 'Numéro du bénéficiaire',
        },
        isAddressSameAsSite: {
          label:
            "Est-ce que l'adresse du bénéficiaire est la même que l'adresse du chantier ?",
        },
      },

      address: {
        label: 'Adresse du bénéficiaire',
        placeholder: 'Adresse du bénéficiaire',
      },
      city: {
        label: 'Ville du bénéficiaire',
        placeholder: 'Ville du bénéficiaire',
      },
      postalCode: {
        label: 'Code postal du bénéficiaire',
        placeholder: 'Code postal du bénéficiaire',
      },

      isElectronicContract: {
        label:
          'Autoriser Hellio à envoyer des documents en signature Electronique',
      },
      comment: {
        label: 'Commentaires et spécifications du projet :',
        placeholder: 'Commentaires',
      },
      SiretInfos: {
        siret_entreprise_travaux: {
          label: "Saisissez le SIRET de l'entreprise de travaux",
          placeholder: "SIRET de l'entreprise de travaux",
          noData: "Je ne trouve pas l'adresse",
        },
      },
      interlocuteur: {
        label: "Interlocuteur de l'entreprise de travaux ",
        placeholder: "Interlocuteur de l'entreprise de travaux ",
        defaultOption: {
          value: "Je ne trouve pas l'interlocuteur de l'entreprise de travaux",
        },
      },
      interlocuteur_infos: {
        civilite: {
          label: 'Civilité',
          mrs: 'Madame',
          mr: 'Monsieur',
        },
        lastName: {
          label: 'Nom de l’interlocuteur',
          placeholder: 'Nom de l’interlocuteur',
        },
        firstName: {
          label: 'Prénom de l’interlocuteur',
          placeholder: 'Prénom de l’interlocuteur',
        },
        email: {
          label: 'Adresse e-mail de l’interlocuteur',
          placeholder: 'Adresse e-mail de l’interlocuteur',
        },
        phone: {
          label: 'Téléphone de l’interlocuteur',
          placeholder: 'Téléphone de l’interlocuteur',
        },
        entreprise_name: {
          label: "Nom de l'entreprise de l'interlocuteur",
          placeholder: "Nom de l'entreprise de l'interlocuteur",
        },
      },
    },
    soumettre_mon_dossier: 'Soumettre mon dossier',

    modale: {
      creation_projet: "Création d'un projet",
      acceder_suivi_projets: 'Accéder au suivi des projets',
      description_part1:
        'Votre simulation a été enregistrée et créée sous forme de projet.',
      description_part2:
        "Vous pouvez commencer à la compléter afin d'obtenir votre financement CEE.",
      error: "Une erreur s'est produite lors de la soumission de votre dossier",
    },
    fields: {
      erreurs: {
        siret_14_caracteres: 'Le SIRET doit contenir 14 caractères',
        siret_chiffres: 'Le SIRET doit être composé uniquement de chiffres',
        nombre_positif: 'Le nombre de bâtiments doit être positif',
        invalid_number: 'Le champs ne doit contenir que des chiffres',
        invalid_postal_code: 'Le code postal doit être composé de 5 chiffres',
      },
      siret: 'Numéro SIRET',
      siret_non_trouve: "Je ne trouve pas le SIRET de l'entreprise de travaux",
      interlocuteur: 'Interlocuteur',
      interlocuteur_non_trouve:
        "Je ne trouve pas l'interlocuteur de l'entreprise de travaux",
    },
  },
  dossiers: {
    hero: 'Suivi de dossier',
    filter: 'Filtres',
    referent: 'Référent commercial',
    keyword: 'Référence chantier, adresse des travaux...',
    filter_statuts: 'Filtrer par statuts',
    filter_referent: 'Filtrer par référent commercial',
    export: 'Exporter les données (.csv)',
    reset: 'Réinitialisation',
  },
  dossier_table: {
    no_data: 'Aucune donnée disponible',
    beneficiaire: 'Bénéficiaire',
    site_nom: 'Nom du site chantier',
    site_adresse: 'Adresse des travaux',
    dateModification: 'Date de mise à jour',
    chantier_dateFinTravaux: 'Date de fin des travaux',
    chantier_reference: 'Référence chantier',
    chantier_montant: 'Montant des primes',
    statut: 'Statut',
    referentCommercial: 'Référent commercial',
    operation_libelle: 'Opération',
    operation_fost: 'Fiche d’opération standardisée',
    operation_montantPrime: 'Montant prime d’opération',
    details: 'Voir détail',
    user: "Raison sociale de l'installateur",
    default_type_logement: 'Logement',
    nom_site_particulier: '{{type}} de {{civilite}} {{prenom}} {{nom}}',
  },
  dossier: {
    hero: 'Vue détaillée du dossier',
    history_title: 'Historique des statuts',
    reference_chantier: 'Référence chantier',
    reference_dossier: 'Référence du dossier',
    statut_dossier: 'Statut du dossier',
    referent_commercial: 'Référent commercial',
    infos_chantier: 'Informations concernant le chantier',
    coordonnees_beneficiaire: 'Coordonnées du Bénéficiaire',
    raison_sociale: 'Raison sociale',
    siren: 'Numéro de SIREN',
    adresse_siege: 'Adresse du siège',
    nom_beneficiaire: 'Nom du bénéficiaire',
    prenom_beneficiaire: 'Prénom du bénéficiaire',
    email_beneficiaire: 'Email du bénéficiaire',
    telephone_beneficiaire: 'Téléphone du bénéficiaire',
    chantier: 'Chantier',
    nom_site: 'Nom du site',
    adresse_chantier: 'Adresse du chantier',
    raison_sociale_installateur: 'Raison sociale de l’installateur',
    siret_installateur: 'SIRET de l’installateur',
    montant_chantier: 'Montant du chantier',
    modele_incitation: 'Choix du modèle d’incitation financière',
    operations: 'Opérations',
    messagerie: 'Messagerie',
    info_operations: 'Informations concernant les opérations',
    libelle_operation: "Libellé de l'opération",
    fiche_operation_standardisee: "Fiche d'opération standardisée",
    fost: 'FOST',
    criteres_eligibilite: "Critères d'éligibilité",
    mentions_facture: 'Mentions à faire figurer sur le devis / facture',
    montant_prime_operation: "Montant prime d'opération",
    pieces_dossier: 'Pièces du dossier',
    statut: 'Statut',
    aucun: 'Aucun',
  },
  attachments: {
    ajouter_fichier: 'Ajouter un fichier',
    deposez_fichiers: 'ou déposez vos fichiers ici',
    error_type: "Le type de fichier {{type}} n'est pas autorisé.",
    error_size: 'La taille des fichiers est limitée à {{size}} Mo.',
    download_error: 'Erreur de téléchargement',
    download_error_description:
      "Le fichier {{file}} n'a pas pu être téléchargé :",
    delete_error: 'Erreur de suppression',
    delete_error_description: "Le fichier {{file}} n'a pas pu être supprimé :",
    upload_error: 'Erreur de chargement',
    upload_error_description: "Le fichier {{file}} n'a pas pu être ajouté :",
  },
};
